import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import { debounce } from 'lodash'

export default function useList() {
    const refListTable = ref(null)

    const toast = useToast()

    store.dispatch('tenant/getAll')
    store.dispatch('cm_reseller/getAll')
    store.dispatch('cm_camera/getAll')

    const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
    const tenantFilter = ref(null)

    const resellerOptions = computed(() => store.getters['cm_reseller/getSelectedItems'])
    const resellerFilter = ref(null)

    const cameraOptions = computed(() => store.getters['cm_camera/getSelectedItems'])
    const cameraFilter = ref(null)

    const startDatetimeFilter = ref(null)
    const endDatetimeFilter = ref(null)

    const action = ref('list')

    const tableColumns = [
      { label: 'camera.title', key: 'camera.name', sortable: true },
      { label: 'camera.camera_id', key: 'camera.cameraId', sortable: true },
      { label: 'reseller.title', key: 'camera.reseller.contactEmail', sortable: true },
      { label: 'tenants.title', key: 'camera.reseller.tenant.name', sortable: true },
      { label: 'status_cameras.online', key: 'online', sortable: true },
      { label: 'date.created_at', key: 'created', sortable: true, formatter: 'formatDateAssigned' },
    ]

    const perPage = ref(10)
    const totalList = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const buttonExport = ref(false)

    const dataMeta = computed(() => {
      const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalList.value,
      }
    })

    const fetchList = (ctx, callback) => {
      store.dispatch('camera_status/getAll', {
        q: searchQuery.value,
        max: perPage.value,
        'page[n]': currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        tenant: tenantFilter.value,
        reseller: resellerFilter.value,
        camera: cameraFilter.value,
        from: startDatetimeFilter.value,
        to: endDatetimeFilter.value,
        action: action.value
      })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
        } else {
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        buttonExport.value = false
      })
    }

    const formatDateAssigned = value => {
      const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
      return formattedDate
    }

    const refetchData = () => {
      refListTable.value.refresh()
    }

    watch([
      tenantFilter,
      resellerFilter,
      cameraFilter,
      currentPage,
      perPage,
      startDatetimeFilter,
      endDatetimeFilter,
    ], () => {
        refetchData()
    })

    watch([ searchQuery ], debounce(() => {
      refetchData()
    }, 500))

    return {
      tableColumns,
      perPage,
      currentPage,
      totalList,
      fetchList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      formatDateAssigned,

      tenantOptions,
      tenantFilter,

      resellerOptions,
      resellerFilter,

      cameraOptions,
      cameraFilter,

      startDatetimeFilter,
      endDatetimeFilter,

      refetchData,
      action,
      buttonExport,
    }
}
