<template>
  <div>
    <b-breadcrumb class="my-2 ml-1">
      <b-breadcrumb-item to="/inicio" class="text-nowrap text-capitalize">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        <span class="ml-1">{{ $t("home") }}</span>
      </b-breadcrumb-item>
      <b-breadcrumb-item
        v-for="(item, index) in getNavs"
        :to="item.to"
        class="text-nowrap text-capitalize"
        :key="index"
        :active="item.active"
      >
      <span v-html="formatBreadcrumbText($tc(item.text, 1))">
      </span>

      </b-breadcrumb-item>
    </b-breadcrumb>

  </div>
</template>
<script>
import store from "@/store";
import { BBreadcrumb } from "bootstrap-vue";


export default {
  components: {
    BBreadcrumb,
  },
  props: ["nameNav", "itemFilter", "searchQuery"],
  data() {
    return {
      pathItem: this.$route.path,
    };
  },
  computed: {
    getNavs() {
      return store.getters["breadcrumbNav/getItemsNav"];
    },
    skin() {
      return store.state.appConfig.layout.skin
    }

  },
  methods: {
    formatBreadcrumbText(text) {
      const strokeColor = this.skin == 'dark' ? 'white' : '#6e6b7b';
      const iconSvg = `
        <svg xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='${strokeColor}'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          class='feather feather-chevron-right svg-icon-breadcrumb'
          style='height: 1rem; width: 1rem;'
        >
          <polyline points='9 18 15 12 9 6'></polyline>
        </svg>
      `;

      const formattedText = text.replace(/>/g, iconSvg);

      return formattedText;
    },
    setIntoNav() {
      store.dispatch("breadcrumbNav/intoItemsNav", {
        to: this.$route.path,
        text: this.nameNav,
        itemFilter: [],
        searchQuery: "",
        active: true,
      });
    },
    setIntoNavBeforeDestroy() {
      if (this.itemFilter == []) {
        store.dispatch("breadcrumbNav/updateItemsNav", {
          to: this.pathItem,
          itemFilter: [],
          searchQuery: this.searchQuery,
          active: true,
        });
      } else {
        store.dispatch("breadcrumbNav/updateItemsNav", {
          to: this.pathItem,
          itemFilter: this.itemFilter,
          searchQuery: this.searchQuery,
          active: true,
        });
      }
    },
    sendItemFilterSearch() {
      this.$emit("itemFilterSearch", this.getNavs);
    },
  },
  created() {
    store.dispatch("breadcrumbNav/clearBreadcrumbs");
    this.setIntoNav();
    this.sendItemFilterSearch();
  },
  beforeDestroy() {
    this.setIntoNavBeforeDestroy();
  },
};
</script>
