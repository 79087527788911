<template>
  <div>
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <!-- Filters -->
    <filters
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      :reseller-filter.sync="resellerFilter"
      :reseller-options="resellerOptions"
      :camera-filter.sync="cameraFilter"
      :camera-options="cameraOptions"
      :start-datetime.sync="startDatetimeFilter"
      :end-datetime.sync="endDatetimeFilter"
    />
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-show="false"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon
                  icon="DownloadCloudIcon"
                  size="15"
                />
              </b-button>
            </div>
          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import useList from "./useList";
import Filters from "./Filters.vue";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";


export default {
  components: {
    Filters,
    Breadcrumb,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      resellerOptions,
      resellerFilter,
      cameraOptions,
      cameraFilter,
      startDatetimeFilter,
      endDatetimeFilter,
      action,
      buttonExport,
    } = useList();

    const exportExcel = () => {
      action.value = 'export'
      buttonExport.value = true;
      fetchList()
    }

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };

    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.camera_manager.status_cameras");

    return {
      nameNav,
      addItemFilter,
      addItemFilterSearch,
      itemFilter,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      resellerOptions,
      resellerFilter,
      cameraOptions,
      cameraFilter,
      startDatetimeFilter,
      endDatetimeFilter,
      exportExcel,
      buttonExport,
    };
  },
};
</script>