<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{$tc('tenants.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{$tc('reseller.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="resellerFilter"
            :options="resellerOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:resellerFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{$tc('camera.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="cameraFilter"
            :options="cameraOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:cameraFilter', val)"
          />
        </b-col>
      </b-row>
      <b-row align-v="center" class="d-flex align-items-center" v-if="$can('filter_datetime', 'Alarm')">
        <b-col cols="6" md="6" class="mb-md-0 mb-2">
          <b-form-group>
            <label class="text-capitalize">Start Datetime</label>
            <flat-pickr
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s', time_24hr: true}"
              :value="startDatetime"
              @on-change="(selectedDates, val) => $emit('update:startDatetime', val)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" md="6" class="mb-md-0 mb-2">
          <b-form-group>
            <label class="text-capitalize">End Datetime</label>
            <flat-pickr
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s', time_24hr: true}"
              :value="endDatetime"
              @on-change="(selectedDates, val) => $emit('update:endDatetime', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue"
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    resellerOptions: {
      type: Array,
      default: null,
    },
    resellerFilter: {
      type: Array,
      default: null,
    },
    cameraOptions: {
      type: Array,
      default: null,
    },
    cameraFilter: {
      type: Array,
      default: null,
    },
    startDatetime: {
      type: String,
      default: null,
    },
    endDatetime: {
      type: String,
      default: null,
    },
  },
};
</script>